/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BetaIcon } from '@img/jsx-wrapped-images/beta.component';
import { betaIcon } from '@components/views/assessment-view/assessment-view.style';

// baseURL addition
export const BASE_URL = '/api';

// response statuses
export const STATUS = {
  TOO_MANY_REQUESTS: 429,
  UNAUTHORIZED: 401,
};

// headers names
export const xTenant = 'X-Tenant';
export const xAuthToken = 'X-Auth-Token';
export const xsrfCookieName = 'XSRF-TOKEN';
export const xsrfHeaderName = 'X-XSRF-TOKEN';

// strings
export const PAGE_UNDER_CONSTRUCTION = 'Sorry, this page is under construction yet.';
export const ACCESS_DENIED_MESSAGE = "Sorry, we can't let you go there.";

export const ALERT_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
  ERROR: 'error',
};

export const APP_ID = 'prismApp';
export const APP_NAME = 'PRISM';

export enum APP_ROLES {
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  ADMIN = 'ADMIN',
  EXPERT = 'EXPERT',
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
}

export enum APP_REVIEW_ROLES {
  REVIEW_COORDINATOR = 'REVIEW_COORDINATOR',
  REVIEW_EXPERT = 'REVIEW_EXPERT',
  REVIEW_CLIENT = 'REVIEW_CLIENT',
  REVIEW_GUEST = 'REVIEW_GUEST',
}

export const BACK_HOME = 'Back to home';
export const BETA = 'Beta';
export const BTN_CANCEL = 'Cancel';
export const BTN_LOGIN_SSO = 'Continue with EPAM';
export const BTN_TENANT_SELECT = 'Select program';
export const BTN_SIGN_IN = 'Sign in';

export const CATALOG_LIST_LEVEL = {
  template: 0,
  dimension: 1,
  'sub-dimension': 2,
  'sub-sub-dimension': 3,
};

export const CATALOG_LIST_TYPE = {
  TEMPLATE: 'template',
  DIMENSION: 'dimension',
  SUB_DIMENSION: 'sub-dimension',
  SUB_SUB_DIMENSION: 'sub-sub-dimension',
  STATEMENT: 'statement',
};

export const CATALOG_STATE = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const CATALOG_WEIGHT_INFO = {
  DIMENSION:
    'The greater number you’ll put here - the more impact this dimension will have on final score.',
  STATEMENT:
    'The greater number you’ll put here - the more impact this statement will have on final score.',
};

export const DIMENSION_TYPES = {
  CORE: 'CORE',
  PRISM: 'PRISM',
};

export enum INDICATOR_CHOICES_TYPES {
  CHOICE = 'CHOICE',
  RANGE = 'RANGE',
}

export const CONFIRMATION_TYPE = {
  YES: {
    field: 'YES',
    name: 'Yes',
  },
  NO: {
    field: 'NO',
    name: 'No',
  },
};

export enum ANSWER_TYPES {
  RATING = 'RATING',
  BINARY = 'BINARY',
  RADIO_GROUP = 'RADIO_GROUP',
  MULTI_GROUP = 'MULTI_GROUP',
  METRIC = 'METRIC',
  OPEN_ENDED = 'OPEN_ENDED',
}

export enum INDICATORS_TYPES {
  OPPORTUNISTIC = 'OPPORTUNISTIC',
  DEVELOPING = 'DEVELOPING',
  ENGAGING = 'ENGAGING',
  SUSTAINABLE = 'SUSTAINABLE',
  TRANSFORMATIVE = 'TRANSFORMATIVE',
}

export enum DEFAULT_RESULT_SCALE_TYPES {
  FOUNDATIONAL = 'Foundational',
  DEVELOPING = 'Developing',
  MODERATE = 'Moderate',
  OPTIMAL = 'Optimal',
  ADVANCED = 'Advanced',
}

export const INDICATORS_TYPES_VALUE_MAPPER = {
  [INDICATORS_TYPES.OPPORTUNISTIC]: 1,
  [INDICATORS_TYPES.DEVELOPING]: 2,
  [INDICATORS_TYPES.ENGAGING]: 3,
  [INDICATORS_TYPES.SUSTAINABLE]: 4,
  [INDICATORS_TYPES.TRANSFORMATIVE]: 5,
};

export const INDICATORS_TYPES_LABELS = {
  [INDICATORS_TYPES.OPPORTUNISTIC]: 'Opportunistic',
  [INDICATORS_TYPES.DEVELOPING]: 'Developing',
  [INDICATORS_TYPES.ENGAGING]: 'Engaging',
  [INDICATORS_TYPES.SUSTAINABLE]: 'Sustainable',
  [INDICATORS_TYPES.TRANSFORMATIVE]: 'Transformative',
};

export const INDICATORS_BASE_CONFIG = {
  [INDICATORS_TYPES.OPPORTUNISTIC]: {
    name: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.OPPORTUNISTIC],
    description: null,
    recommendation: null,
    mappedValue: '1',
  },
  [INDICATORS_TYPES.DEVELOPING]: {
    name: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.DEVELOPING],
    description: null,
    recommendation: null,
    mappedValue: '2',
  },
  [INDICATORS_TYPES.ENGAGING]: {
    name: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.ENGAGING],
    description: null,
    recommendation: null,
    mappedValue: '3',
  },
  [INDICATORS_TYPES.SUSTAINABLE]: {
    name: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.SUSTAINABLE],
    description: null,
    recommendation: null,
    mappedValue: '4',
  },
  [INDICATORS_TYPES.TRANSFORMATIVE]: {
    name: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.TRANSFORMATIVE],
    description: null,
    recommendation: null,
    mappedValue: '5',
  },
  [CONFIRMATION_TYPE.YES.field]: {
    name: CONFIRMATION_TYPE.YES.name,
    description: null,
    recommendation: null,
    mappedValue: '5',
  },
  [CONFIRMATION_TYPE.NO.field]: {
    name: CONFIRMATION_TYPE.NO.name,
    description: null,
    recommendation: null,
    mappedValue: '1',
  },
};

export const INDICATOR_CHOICES = {
  RATING: [
    {
      choice: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.OPPORTUNISTIC],
    },
    {
      choice: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.DEVELOPING],
    },
    {
      choice: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.ENGAGING],
    },
    {
      choice: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.SUSTAINABLE],
    },
    {
      choice: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.TRANSFORMATIVE],
    },
  ],
  BINARY: [
    {
      choice: CONFIRMATION_TYPE.YES.name,
    },
    {
      choice: CONFIRMATION_TYPE.NO.name,
    },
  ],
  RADIO_GROUP: [
    {
      choice: CONFIRMATION_TYPE.YES.name,
      mappedValue: 5,
      order: 0,
    },
    {
      choice: CONFIRMATION_TYPE.NO.name,
      mappedValue: 1,
      order: 1,
    },
    {
      choice: 'Not Applicable',
      mappedValue: null,
      order: 2,
    },
  ],
  MULTI_GROUP: [
    {
      choice: 'Option #1',
      order: 0,
    },
  ],
  METRIC: [
    {
      lowRange: '',
      highRange: '',
      mappedValue: 1,
      order: 0,
      choiceType: INDICATOR_CHOICES_TYPES.RANGE,
      choice: null,
      id: null,
    },
  ],
};
export const NA_INDICATOR = {
  name: '',
  description: 'Current score is less than 1',
  recommendation: null,
};

export const DEFAULT_INDICATORS = {
  RATING: {
    [INDICATORS_TYPES.OPPORTUNISTIC]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.OPPORTUNISTIC],
    [INDICATORS_TYPES.DEVELOPING]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.DEVELOPING],
    [INDICATORS_TYPES.ENGAGING]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.ENGAGING],
    [INDICATORS_TYPES.SUSTAINABLE]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.SUSTAINABLE],
    [INDICATORS_TYPES.TRANSFORMATIVE]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.TRANSFORMATIVE],
  },
  BINARY: {
    [CONFIRMATION_TYPE.YES.field]: INDICATORS_BASE_CONFIG.YES,
    [CONFIRMATION_TYPE.NO.field]: INDICATORS_BASE_CONFIG.NO,
  },
  RADIO_GROUP: {},
  MULTI_GROUP: {
    [INDICATORS_TYPES.OPPORTUNISTIC]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.OPPORTUNISTIC],
    [INDICATORS_TYPES.DEVELOPING]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.DEVELOPING],
    [INDICATORS_TYPES.ENGAGING]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.ENGAGING],
    [INDICATORS_TYPES.SUSTAINABLE]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.SUSTAINABLE],
    [INDICATORS_TYPES.TRANSFORMATIVE]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.TRANSFORMATIVE],
  },
  METRIC: {},
  OPEN_ENDED: {
    [INDICATORS_TYPES.OPPORTUNISTIC]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.OPPORTUNISTIC],
    [INDICATORS_TYPES.DEVELOPING]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.DEVELOPING],
    [INDICATORS_TYPES.ENGAGING]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.ENGAGING],
    [INDICATORS_TYPES.SUSTAINABLE]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.SUSTAINABLE],
    [INDICATORS_TYPES.TRANSFORMATIVE]: INDICATORS_BASE_CONFIG[INDICATORS_TYPES.TRANSFORMATIVE],
  },
};

export const TABS = {
  MODAL: {
    BUNCH: [
      {
        label: 'Scores',
        value: 0,
      },
      {
        label: 'Moderator',
        value: 1,
      },
      {
        label: 'Tags',
        value: 2,
      },
    ],
    TEMPLATE: [
      {
        label: 'General',
        value: 0,
      },
      {
        label: 'Formula',
        value: 1,
      },
    ],
  },
  NAVBAR: {
    DIMENSION: [
      {
        label: 'Structure',
        value: 0,
      },
      {
        label: 'Supporting Documents',
        value: 1,
      },
    ],
    ASSESSMENT: [
      {
        label: 'Assessment',
        value: 0,
      },
      {
        label: 'Results',
        value: 1,
      },
      {
        label: 'Attachments',
        value: 2,
      },
      {
        label: (
          <>
            <span>Diagram Builder</span>
            <span css={betaIcon}>
              <BetaIcon />
            </span>
          </>
        ),
        value: 3,
      },
    ],
  },
};

export const DEFAULT_INDICATOR_TEXT =
  '<div></div><p>No data for this indicator, sorry.</p><p>Please ask dimension owner to put indicator in the catalog.</p><div></div>';

export const DEFAULT_MAX_SCORE_VALUE = 5;
export const DEFAULT_PAGE = 1;

export const DEFAULT_TEXT_ELLIPSIS_CONF = {
  maxLine: 3,
  lineHeight: 20,
};

export const DEFAULT_WEIGHT = 1;

export enum DIMENSION_VALIDATION_STATE {
  VALID = 'VALID',
  INVALID = 'NO_STATEMENTS',
  WARNING = 'EXCLAMATION_MARK',
}

export const DIMENSION_ACTION = {
  COPY: 'copy',
  DUPLICATE: 'duplicate',
};

export const EMAIL = 'E-mail';

export const FILE_TYPE = {
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF: 'application/pdf',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JSON: 'application/json',
};

export const FORMULA = [
  {
    value: 'AVERAGE',
    label: 'Average',
  },
  {
    value: 'YES_FIVE_NO_ONE',
    label: 'Yes = 5, No = 1',
  },
  {
    value: 'ENGX_SURVEY_LITE',
    label: 'EngX Survey Lite',
  },
  {
    value: 'CSATx',
    label: 'CSATx',
  },
];

export const FORMULA_LABEL = {
  AVERAGE: 'Average',
  YES_FIVE_NO_ONE: 'Yes = 5, No = 1',
  ENGX_SURVEY_LITE: 'EngX Survey Lite',
  CSATx: 'CSATx',
};

export const GRAPH_CONSTANTS = {
  SPIDER: 'spider',
  RADIAL: 'radial',
};

export const HISTORY_EMPTY = 'Sorry, no history records were found';

export const HINT = {
  REVIEW_USER_N_ROLE:
    'You can add a user and select a role for the assessment. Email notification will be sent to the user with information about addition to the assessment.',
};

export const INDUSTRY_BENCHMARK = 'Industry Benchmark';

export const LOGIC = {
  CONDITION: {
    TERMS: [
      {
        label: 'equals',
        value: '==',
      },
    ],
  },
  ACTION: {
    STATUS: [
      {
        label: 'Visible',
        value: 'VISIBLE',
      },
    ],
  },
  OPERATORS: [
    {
      label: 'And',
      value: 'AND',
    },
    {
      label: 'Or',
      value: 'OR',
    },
  ],
};

export const LOGIN_PAGE_ERRORS = {
  credentials: {
    invalid: 'Invalid e-mail or password',
    blocked: "You've exceeded max number of login attempts. Please try again in 5 minutes",
  },
};

export const MAX_CHART_SELECTED_DATA = 3;
export const MAX_CHART_TEXT_LENGTH = 24;
export const MAX_SUNBURST_TEXT_LENGTH = 30;
export const MAX_PAGE_COUNT = 25;
export const MAX_SCROLL_COUNT = {
  PRODUCT: 30,
  USER: 10,
};

export const MAX_INDICATOR_LENGTH = 60;
export const MAX_TAGS_IN_SUPER_TAG_LENGTH = 3;
export const MAX_TAGS_LENGTH = 2;
export const MAX_TEXT_LENGTH = 320;

export const MESSAGE = {
  SUCCESS: {
    DEFAULT: 'Everything was saved.',
    DRAFT: 'Your changes was saved as draft',
    REVIEW_FINISH: 'Assessment was finished',
    REVIEW_UNFINISH: 'Assessment back in progress',
    VOTER_REVIEW_FINISH: (name: string) => `Survey for "${name}" was finished`,
    REVIEW_VOTES_SAVED: 'Your votes have been saved',
    SHARED: 'Assessment was shared with selected users',
    STRUCTURE: 'Assessment Structure was updated',
    SYNC: 'Assessment Structure was synced with Catalog',
    USER_REGISTRATION: 'User has been successfully added.',
    USER_RESET: 'User password was reset.',
    DIMENSION_COPY: 'Dimension was successfully copied to your tenant',
    ROLE_UPDATED: 'User role successfully updated.',
    ACCESS_GRANTED: 'Access successfully granted.',
    COMMENT: {
      SAVE: 'Comment successfully saved.',
    },
    CHANGES_SAVED: 'Changes saved',
    DOCUMENT_ADDED: 'Document successfully added',
    TEMPLATE: {
      SAVE: (name: string) => `New Template "${name}" was successfully created`,
      UPDATE: (name: string) => `Template "${name}" was successfully updated`,
    },
    CAMPAIGN: {
      SAVE: (name: string) => `New “${name}” was successfully created`,
    },
    VOTER: {
      SAVE: (name: string) => `"${name}" page was created`,
      RENAME: (name: string) => `"${name}"  page was renamed`,
      DELETE: (name: string) => `"${name}" page was deleted`,
      IN_PROGRESS: 'Survey answers back in progress',
    },
    REPLY_SAVED: 'Message successfully saved.',
    REPLY_REMOVED: 'Message deleted.',
  },
  FAILURE: {
    DEFAULT: 'Something went wrong.',
    MODAL_EMAIL_SAVE: 'E-mail must be specified',
    WRONG_EMAIL_FORMAT: 'Invalid email format',
    MODAL_ROLE_SAVE: 'Role must be specified',
    MODAL_TENANT_SAVE: 'Tenant must be specified',
    FOLLOW_UP: 'Sorry! Requested review to create follow-up does not exist.',
    USER_REGISTRATION: 'User has not been added.',
    USER_RESET: 'User password was not reset.',
    REVIEW_FINISHED_UPDATE: 'This assessment has been finished and cannot be changed',
    REVIEW_PERMISSIONS: "You don't have permissions to create or update review.",
    LOGIC: {
      STATEMENT: 'Statement must be specified',
      TERMS: 'Term must be specified',
      CHOICE: 'Option must be specified',
      ACTION: 'Action must be specified',
    },
    USER_ALREADY_IN_TENANT:
      'User exists in the current tenant. Use "Grant access" button to provide permissions.',
    USER_ALREADY_IN_UNIT: 'User is already added to the current unit.',
    USER_HAS_NO_PERMISSION_IN_UNIT:
      'Users below have no minimal required permissions in the selected units. They will be excluded from campaign participants.  To prevent exclusion from the campaign please use "Permissions" tab to provide the required access to the units.',
    MODAL_NAME_SAVE: 'Name must be specified',
    MODAL_NAME_EXIST: 'This template name exists. Try another',
    MODAL_FIELD_EMPTY: "This field can't be empty",
    TAXONOMY_MODAL_NAME_SAVE: 'Taxonomy name must be specified',
    TAXONOMY_MODAL_DATASOURCE_SAVE: 'Taxonomies data source must be specified',
    ROLE_SAVE: 'Role must be specified',
    MODAL_SCHEMA_SAVE: 'Schema must be specified',
    CANCELED: {
      response: {
        canceled: 'repsonse is canceled',
      },
    },
    SELECT_UNIT: 'Select at least one unit',
    STATEMENT_TYPE: 'Type must be selected',
    SUPPORTING_QUESTIONS_SAVE: 'Question must be specified',
    VOTER: {
      RENAME: 'This page name exists. Try another',
    },
    WEIGHT: 'Weight should be greater than zero',
    LEVEL_NAME_SAVE: 'Level name must be specified',
    MODERATORS_REQUIRED: 'Moderator must be specified',
    SPECIFIED_DATE: 'Date must be specified',
    VALIDATE_DATE: 'End date must be later than the start date',
    SPECIFIED_DURATION: 'Duration must be specified',
    DURATION_INCORRECT: 'The duration is set incorrectly',
    COORDINATOR_REQUIRED: 'At least one Coordinator must be assigned',
    FIELD_REQUIRED: 'Field must be specified',
    CAMPAIGN_REQUIRED: 'Campaign name must be specified',
    PORTFOLIO_REQUIRED: 'Portfolio must be specified',
    INVALID_PORTFOLIO:
      'The previously selected portfolio is invalid. Please select another portfolio',
  },
  INFO: {
    AUTOCOMPLETE: {
      title: 'Button “Default Structure”',
      content:
        'Default Structure will automatically form the structure of the Survey in the same sequence as reflected in the «Survey Questions Catalog» box. The number of pages and their titles will match to the number of selected dimensions / sub / sub-sub. The page shows all selected statements of the given dimensions / sub / sub-sub.',
    },
    REVIEW_STRUCTURE:
      'Please check if all Statements are bound to Dimensions or Sub-Dimensions, otherwise they will not participate in Assessment.',
    SNAP: {
      TITLE: 'Aw, snap!',
      DESCRIPTION:
        'A team of highly trained monkeys has been dispatched to deal with this situation.',
      UID_INFO: 'Please include the following information in your report: ',
    },
    USER_ASSESSMENT: 'No assessment has been added',
    USER_INFO: 'Email notification with credentials will be sent to a new user.',
  },
};

export const MAX_USERS_LENGTH = 3;
export const MAX_WEIGHT_LENGTH = 6;

export const MODAL = {
  LEAVE: {
    title: 'Leave page',
    description: 'Are you sure you want to leave the page? All information will be lost.',
  },
};

export const MODERATORS_INFO = 'You can add a moderator only for Dimensions.';
export const NAME_MAX_LENGTH = 512;

export const NAVIGATION_CONTAINER_SIZE = {
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

export const NOT_FOUND_MESSAGE = "The page you are looking for can't be found";
export const NOTE_MAX_LENGTH = 2048;
export const PASSWORD = 'Password';
export const PASSWORD_HIDE = 'Hide password';
export const PASSWORD_SHOW = 'Show password';

export enum UNIT_TYPE {
  TENANT = 'Tenant',
  ACCOUNT = 'Account',
  PROGRAM = 'Program',
  PROJECT = 'Project',
  STREAM = 'Stream',
  ORGANIZATION = 'Organization',
}

export const RECOMMENDATIONS_DEFAULT_TEXT =
  '<p><strong>GENERAL COMMENTS</strong></p><p><br></p><p><strong>RECOMMENDATIONS</strong></p>';

export enum REVIEW_STATUS_TYPES {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  NON_STARTED = 'NON_STARTED',
  UNKNOWN = 'UNKNOWN',
}

export const REVIEW_STATUS = {
  [REVIEW_STATUS_TYPES.DONE]: {
    color: '#9bc837',
    title: 'Done',
  },
  [REVIEW_STATUS_TYPES.IN_PROGRESS]: {
    color: '#f6a024',
    title: 'In progress',
  },
  [REVIEW_STATUS_TYPES.NON_STARTED]: {
    color: '#cfd1db',
    title: 'Non-started',
  },
  [REVIEW_STATUS_TYPES.UNKNOWN]: {
    color: '#cfd1db',
  },
};

export const REVIEW_STRUCTURE = {
  DIMENSION: {
    title: 'Dimensions',
    description: 'You can add any number of Dimensions from the catalog.',
    info: 'You can make your own assessment structure.',
  },
  TEMPLATE: {
    title: 'Template',
    description: 'You can add only one selected Template.',
    info: 'You have selected Template from the catalog.',
  },
};

export const REVIEW_TEMPLATE_EMPTY = 'Sorry, no templates were found';

export enum REVIEW_TYPE {
  ASSESSMENT = 'ASSESSMENT',
  SURVEY = 'SURVEY',
}

export const REVIEW_TYPE_LIST = [
  {
    id: 'ASSESSMENT',
    name: 'Assessment',
  },
  {
    id: 'SURVEY',
    name: 'Survey',
  },
];

export const REVIEW_TYPE_LABEL = {
  ASSESSMENT: 'Assessment',
  SURVEY: 'Survey',
};

export const SCALE_TYPE = {
  RATING: {
    value: ANSWER_TYPES.RATING,
    label: 'Rating',
  },
  BINARY: {
    value: ANSWER_TYPES.BINARY,
    label: 'Yes/No',
  },
  RADIO_GROUP: {
    value: ANSWER_TYPES.RADIO_GROUP,
    label: 'Single Select - Multiple Choice',
  },
  MULTI_GROUP: {
    value: ANSWER_TYPES.MULTI_GROUP,
    label: 'Multi Select - Multiple Choice',
  },
  METRIC: {
    value: ANSWER_TYPES.METRIC,
    label: 'Metric',
  },
  OPEN_ENDED: {
    value: ANSWER_TYPES.OPEN_ENDED,
    label: 'Open-Ended Question',
  },
};

export const SCORE_STYLE = {
  color: '#30B6DD',
  color70: 'rgba(48,182,221.70)',
  backgroundColor: 'rgba(48,182,221,0.10)',
  borderColor: 'rgba(48,182,221,0.50)',
};

export const SCORE_SCALE_STYLE = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: 'There is no data yet.',
    style: {
      color: '#e1e3eb',
      color70: 'rgba(225,227,235,0.70)',
      backgroundColor: 'rgba(225,227,235,0.10)',
      borderColor: 'rgba(225,227,235,0.50)',
    },
  },
  [INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: '#c03835',
    label: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.OPPORTUNISTIC],
    text: 'Not fully achieving any of the essential dimensions; Fragmented business processes.',
    style: {
      color: '#b32424',
      color70: 'rgba(179,36,36,0.70)',
      backgroundColor: 'rgba(179,36,36,0.10)',
      borderColor: 'rgba(179,36,36,0.50)',
    },
  },
  [INDICATORS_TYPES.DEVELOPING]: {
    color: '#e55340',
    label: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.DEVELOPING],
    text: 'A few essential dimensions achieved fully, others partially; Generally aware of need to change.',
    style: {
      color: '#fa4b4b',
      color70: 'rgba(250,75,75,0.70)',
      backgroundColor: 'rgba(250,75,75,0.10)',
      borderColor: 'rgba(250,75,75,0.50)',
    },
  },
  [INDICATORS_TYPES.ENGAGING]: {
    color: '#fbaa2a',
    label: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.ENGAGING],
    text: 'Intentionally achieving at least half of the dimensions; Plans to achieve the rest.',
    style: {
      color: '#fcaa00',
      color70: 'rgba(252,170,0,0.70)',
      backgroundColor: 'rgba(252,170,0,0.10)',
      borderColor: 'rgba(252,170,0,0.50)',
    },
  },
  [INDICATORS_TYPES.SUSTAINABLE]: {
    color: '#4ab16a',
    label: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.SUSTAINABLE],
    text: 'Intentionally achieving nearly all dimensions; Proactively achieve new methods and technologies.',
    style: {
      color: '#9BC837',
      color70: 'rgba(155,200,55,0.70)',
      backgroundColor: 'rgba(155,200,55,0.10)',
      borderColor: 'rgba(155,200,55,0.50)',
    },
  },
  [INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: '#1cc4a6',
    label: INDICATORS_TYPES_LABELS[INDICATORS_TYPES.TRANSFORMATIVE],
    text: 'Repeatedly achieving all dimensions; Process improvement in place; Driving enterprise transformation.',
    style: {
      color: '#1cb91e',
      color70: 'rgba(28,185,30,0.70)',
      backgroundColor: 'rgba(28,185,30,0.10)',
      borderColor: 'rgba(28,185,30,0.50)',
    },
  },
  [CONFIRMATION_TYPE.NO.field]: {
    color: '#c03835',
    label: CONFIRMATION_TYPE.NO.name,
    text: CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: SCORE_STYLE,
  },
  [CONFIRMATION_TYPE.YES.field]: {
    color: '#1cc4a6',
    label: CONFIRMATION_TYPE.YES.name,
    text: CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const getScoreScaleColorByTheme = (scoreStyle = SCORE_SCALE_STYLE) => ({
  RATING: {
    0: scoreStyle.NA,
    1: scoreStyle[INDICATORS_TYPES.OPPORTUNISTIC],
    2: scoreStyle[INDICATORS_TYPES.DEVELOPING],
    3: scoreStyle[INDICATORS_TYPES.ENGAGING],
    4: scoreStyle[INDICATORS_TYPES.SUSTAINABLE],
    5: scoreStyle[INDICATORS_TYPES.TRANSFORMATIVE],
  },
  BINARY: {
    null: scoreStyle.NA,
    NO: scoreStyle.NO,
    YES: scoreStyle.YES,
  },
  RADIO_GROUP: scoreStyle.NUM_ORDER,
  MULTI_GROUP: {
    0: scoreStyle.NA,
    1: scoreStyle[INDICATORS_TYPES.OPPORTUNISTIC],
    2: scoreStyle[INDICATORS_TYPES.DEVELOPING],
    3: scoreStyle[INDICATORS_TYPES.ENGAGING],
    4: scoreStyle[INDICATORS_TYPES.SUSTAINABLE],
    5: scoreStyle[INDICATORS_TYPES.TRANSFORMATIVE],
  },
  METRIC: scoreStyle.NUM_ORDER,
  OPEN_ENDED: {
    0: scoreStyle.NA,
    1: scoreStyle[INDICATORS_TYPES.OPPORTUNISTIC],
    2: scoreStyle[INDICATORS_TYPES.DEVELOPING],
    3: scoreStyle[INDICATORS_TYPES.ENGAGING],
    4: scoreStyle[INDICATORS_TYPES.SUSTAINABLE],
    5: scoreStyle[INDICATORS_TYPES.TRANSFORMATIVE],
  },
});

export const SCORE_SCALE = {
  RATING: {
    0: SCORE_SCALE_STYLE.NA,
    1: SCORE_SCALE_STYLE[INDICATORS_TYPES.OPPORTUNISTIC],
    2: SCORE_SCALE_STYLE[INDICATORS_TYPES.DEVELOPING],
    3: SCORE_SCALE_STYLE[INDICATORS_TYPES.ENGAGING],
    4: SCORE_SCALE_STYLE[INDICATORS_TYPES.SUSTAINABLE],
    5: SCORE_SCALE_STYLE[INDICATORS_TYPES.TRANSFORMATIVE],
  },
  BINARY: {
    null: SCORE_SCALE_STYLE.NA,
    NO: SCORE_SCALE_STYLE.NO,
    YES: SCORE_SCALE_STYLE.YES,
  },
  RADIO_GROUP: SCORE_SCALE_STYLE.NUM_ORDER,
  MULTI_GROUP: {
    0: SCORE_SCALE_STYLE.NA,
    1: SCORE_SCALE_STYLE[INDICATORS_TYPES.OPPORTUNISTIC],
    2: SCORE_SCALE_STYLE[INDICATORS_TYPES.DEVELOPING],
    3: SCORE_SCALE_STYLE[INDICATORS_TYPES.ENGAGING],
    4: SCORE_SCALE_STYLE[INDICATORS_TYPES.SUSTAINABLE],
    5: SCORE_SCALE_STYLE[INDICATORS_TYPES.TRANSFORMATIVE],
  },
  METRIC: SCORE_SCALE_STYLE.NUM_ORDER,
  OPEN_ENDED: {
    0: SCORE_SCALE_STYLE.NA,
    1: SCORE_SCALE_STYLE[INDICATORS_TYPES.OPPORTUNISTIC],
    2: SCORE_SCALE_STYLE[INDICATORS_TYPES.DEVELOPING],
    3: SCORE_SCALE_STYLE[INDICATORS_TYPES.ENGAGING],
    4: SCORE_SCALE_STYLE[INDICATORS_TYPES.SUSTAINABLE],
    5: SCORE_SCALE_STYLE[INDICATORS_TYPES.TRANSFORMATIVE],
  },
};

export const RATING_SCORE_INDICATOR = {
  'N/A': 0,
  OPPORTUNISTIC: 1,
  DEVELOPING: 2,
  ENGAGING: 3,
  SUSTAINABLE: 4,
  TRANSFORMATIVE: 5,
};

export const SCORE_INDICATOR = {
  RATING: RATING_SCORE_INDICATOR,
  BINARY: {
    'N/A': 0,
    NO: 'NO',
    YES: 'YES',
  },
  RADIO_GROUP: {},
  MULTI_GROUP: RATING_SCORE_INDICATOR,
  METRIC: {
    'N/A': 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
  },
  OPEN_ENDED: RATING_SCORE_INDICATOR,
};

export const SCORE_TYPE = {
  ALL: 'ALL',
  EXPECTED: 'EXPECTED',
  EXPERT: 'EXPERT',
  CLIENT: 'CLIENT',
};

export const SCORE_CHART_COLOR_SET = [
  '#2196f3',
  '#4caf50',
  '#fdd63b',
  '#fd8197',
  '#cab5fc',
  '#95eaff',
  '#b3c91f',
  '#f6a024',
  '#e91e63',
  '#0453c7',
  '#46c8d3',
  '#6362c9',
  '#9c27b0',
  '#ff8561',
  '#c01a17',
];

const COLORS_CONSTANTS = {
  POINT_BACKGROUND_COLOR: 'rgba(33, 150, 243,0.95)',
};

export const SCORE_CHART_COLOR = {
  EXPECTED: {
    backgroundColor: 'rgba(135, 211, 124,0)',
    borderColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
    borderWidth: 2,
    radius: 0,
    pointRadius: 0,
    pointHoverRadius: 5,
    pointBackgroundColor: 'rgba(135, 211, 124,0.75)',
    pointBorderColor: 'rgba(135, 211, 124,0.75)',
    pointHoverBackgroundColor: 'rgba(135, 211, 124,0.95)',
    pointHoverBorderColor: 'rgba(135, 211, 124,0.95)',
  },
  ALL: {
    backgroundColor: 'rgba(240,98,146,0.25)',
    borderColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
    borderWidth: 2,
    radius: 5,
    pointRadius: 5,
    pointHoverRadius: 5,
    pointBackgroundColor: 'rgba(240,98,146,0.75)',
    pointBorderColor: 'rgba(240,98,146,0.75)',
    pointHoverBackgroundColor: 'rgba(240,98,146,0.95)',
    pointHoverBorderColor: 'rgba(240,98,146,0.95)',
  },
  EXPERT: {
    backgroundColor: 'rgba(149,117,205,0.25)',
    borderColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
    borderWidth: 2,
    radius: 5,
    pointRadius: 5,
    pointHoverRadius: 5,
    pointBackgroundColor: 'rgba(149,117,205,0.75)',
    pointBorderColor: 'rgba(149,117,205,0.75)',
    pointHoverBackgroundColor: 'rgba(149,117,205,0.95)',
    pointHoverBorderColor: 'rgba(149,117,205,0.95)',
  },
  CLIENT: {
    backgroundColor: 'rgba(79,195,247,0.25)',
    borderColor: 'rgba(79,195,247,0.75)',
    borderWidth: 2,
    radius: 5,
    pointRadius: 5,
    pointHoverRadius: 5,
    pointBackgroundColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
    pointBorderColor: 'rgba(79,195,247,0.75)',
    pointHoverBackgroundColor: 'rgba(79,195,247,0.95)',
    pointHoverBorderColor: 'rgba(79,195,247,0.95)',
  },
  AVERAGE: {
    backgroundColor: 'rgba(33, 150, 243,0.25)',
    borderColor: 'rgba(33, 150, 243,0.75)',
    borderWidth: 2,
    radius: 5,
    pointRadius: 5,
    pointHoverRadius: 5,
    pointBackgroundColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
    pointBorderColor: 'rgba(33, 150, 243,0.75)',
    pointHoverBackgroundColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
    pointHoverBorderColor: COLORS_CONSTANTS.POINT_BACKGROUND_COLOR,
  },
};

export const SCORE_CHART_TYPE = [
  {
    name: INDUSTRY_BENCHMARK,
    value: SCORE_TYPE.EXPECTED,
    selected: true,
  },
  {
    name: 'Assessors',
    value: SCORE_TYPE.EXPERT,
    selected: true,
  },
  {
    name: 'Respondents',
    value: SCORE_TYPE.CLIENT,
    selected: true,
  },
];

export const SHARE_NO_USERS =
  'No assessors, respondents or viewers added to share. Please use the "Add user" button in campaign edit mode to add users to the assessment.';

export const SORT = {
  CREATED_DATE: {
    ASC: 'createdAt',
    DESC: '-createdAt',
  },
  UPDATED_DATE: {
    ASC: 'updatedAt',
    DESC: '-updatedAt',
  },
  NAME: {
    ASC: 'NAME_ASC',
    DESC: 'NAME_DESC',
  },
  EMAIL: {
    ASC: 'EMAIL_ASC',
    DESC: 'EMAIL_DESC',
  },
  LAST_LOGIN_TIME: {
    ASC: 'LAST_LOGIN_ASC',
    DESC: 'LAST_LOGIN_DESC',
  },
  ORDER: 'order',
};

export const STYLE = {
  BACKGROUND_COLOR: 'backgroundColor',
  BORDER: 'border',
  BORDER_COLOR: 'borderColor',
  COLOR: 'color',
  LEFT: 'left',
  MAX_WIDTH: 'maxWidth',
  WIDTH: 'width',
};

export const SUMMARY_DEFAULT_TEXT =
  '<p><strong>General information</strong></p><p><br></p><p><strong>Things go well</strong></p><p><br></p><p><strong>Opportunities</strong></p>';

export const SUMMARY_NO_TEXT = 'There is no summary.';

export const SUPER_TAGS_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
};
export const TENANT_ACCESS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const TENANT_ACCESS_DENIED = "Sorry, you don't have access to any tenants";

export const TENANT_DETECTOR = {
  // special tenants (deprecated?)
  '974c03d4-6580-4075-a8d9-411594d51e29': {
    CLIENT: 'program',
  },
  '3dfb9f6d-ce9c-42de-99b2-9a8abe351f72': {
    CLIENT: 'application',
  },
};

export const EPAM_DEFAULT_TENANT = 'd01a3d76-98ed-4835-82fe-7e248814bdd3';
export const EPAM_DEFAULT_UNIT_ID = '1';
export const TENANT_SELECT = "You're almost in...";

export const USER_APP_ROLES = [
  {
    id: 'ADMIN',
    name: 'Administrator',
    level: 0,
  },
  {
    id: 'EXPERT',
    name: 'Expert',
    level: 1,
  },
  {
    id: 'MEMBER',
    name: 'Member',
    level: 2,
  },
  {
    id: 'GUEST',
    name: 'Guest',
    level: 3,
  },
];

export const REVIEW_ROLES = [
  {
    id: 'REVIEW_COORDINATOR',
    name: 'Coordinator',
  },
  {
    id: 'REVIEW_EXPERT',
    name: 'Assessor',
  },
  {
    id: 'REVIEW_CLIENT',
    name: 'Respondent',
  },
  {
    id: 'REVIEW_GUEST',
    name: 'Viewer',
  },
];

export const USER_REVIEW_ROLES = {
  REVIEW_COORDINATOR: {
    name: 'Coordinator',
    description: 'Coordinator manages Campaign and all the assessments included in it.',
  },
  REVIEW_EXPERT: {
    name: 'Assessor',
    description:
      'Assessor fills in scores, views the scores and comments of all other assessors and respondents, writes summary.',
  },
  REVIEW_CLIENT: {
    name: 'Respondent',
    description: 'Respondent only fills in scores, sees only own result and comments.',
  },
  REVIEW_GUEST: {
    name: 'Viewer',
    description:
      'Viewer sees the scores and comments of all other assessors and respondents (read only mode for assessment result page)',
  },
};

export const USER_ROLES = {
  GLOBAL_ADMIN: {
    name: 'Global Admin',
    description: 'Administrator has access and all functions to the entire Tenant.',
  },
  ADMIN: {
    name: 'Administrator',
    description:
      'Administrator has access to all functions in the Catalog and units he is added to and below.',
  },
  EXPERT: {
    name: 'Expert',
    description:
      'Expert has permissions to work with the Catalog. Has access to units he is added to and below. Can create campaigns and manage them.',
  },
  MEMBER: {
    name: 'Member',
    description:
      'Member only sees the units he is added to and below and can only access campaigns he is participating in.',
  },
  GUEST: {
    name: 'Guest',
    description: 'Guest only sees the "Catalog" tab (in read only mode).',
  },
};

export enum USER_STATE {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export const initialFilterData = {
  // initial configuration for results filter
  dimensions: [],
  id: '',
  minRange: '',
  maxRange: '',
  reviewId: '',
  userId: '',
  voterIds: [],
};

export enum APP_TYPE {
  standalone = 'standalone',
  widget = 'widget',
}

export const GLOBAL_SPINNER_ID = 'prism-global-spinner';
export const APPLICATION_WRAPPER_ID = 'APPLICATION_WRAPPER';

export const ANONYMOUS_USER_ID = 'anonymous-user';

export const TENANT_TYPES = {
  CATALOG_TENANT: 'CatalogTenant',
  SELECTED_TENANT: 'SelectedTenant',
};

export const HEADER_OPTION_NAMES = {
  NAME: 'Name',
  STATEMENTS: 'Statements',
  MODERATOR: 'Moderator',
  CREATED: 'Created',
  UPDATED: 'Updated',
  AUTORUN_STATUS: 'Autorun status',
};

export const UNIT_SUMMARY_TITLE = 'Unit Summary';
export enum RESULT_TAB_OPTIONS_KEYS {
  HEATMAP = 'heatmap',
  LIST_VIEW = 'listview',
  LIST_VIEW_NEW = 'listview_new',
}

export enum LOCAL_STORAGE_KEYS {
  SHOW_STRUCTURE = 'ShowStructure',
  REVIEW_IDS = 'reviewIds',
  OAUTH_2_ACCESS_TOKEN = 'OAuth2AccessToken',
  SHARE_REVIEW_TOKEN = 'ShareReviewToken',
  PAGE_COUNT = 'PageCount',
  SHOW_ARCHIVED_STRUCTURE = 'ShowArchivedStructure',
  UNIT_ID = 'unitId',
  PRISM_CATALOG_GROUPING = 'PRISM:catalog-grouping',
  ASSESSMENT_RESULT_THEME = 'PRISM:assessmentResultTheme',
  IS_SSO_TOKEN_SET = 'PRISM:isSSOTokenSet',
  HEATMAP_TYPE = 'PRISM:heatmapType',
}

export const RESOURCE_TYPES = {
  VOTER: 'VOTER',
  CATALOG_STATEMENT: 'CATALOG_STATEMENT',
  REVIEW_STATEMENT: 'REVIEW_STATEMENT',
  REVIEW: 'REVIEW',
};

export const DEBOUNCE_INPUT_VALUE = 500;

export const SORTING = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SORT_FIELD = {
  DESCRIPTION: 'DESCRIPTION',
};

export const TIME_CONSTANTS = {
  ONE_TIME: 1000,
  FIVE_MINUTES: 300000,
  ONE_HOUR: 3600000,
  ONE_DAY: 86400000,
};

export const MIN_INPUT_COUNT = 3;

export const HTTP_HEADERS = {
  MULTI_ART_FORM_DATA: 'multipart/form-data',
};

export enum SORTING_FIELDS {
  CREATED_DATE = 'CREATED_DATE',
  UPDATED_DATE = 'UPDATED_DATE',
}

export const LOCK_STATUS = {
  LOCK: 'Lock',
  UNLOCK: 'Unlock',
};

export const CATALOG_ARCHIVE_STATUS = {
  ARCHIVE: 'archive',
  RESTORE: 'restore',
};

export const CATALOG_COMMON_CONSTANTS = {
  DIMENSIONS: 'Dimensions',
  STATEMENTS: 'Statements',
};

export const TENANT_OPTIONS = {
  EDIT: 'Edit',
  HISTORY: 'History',
  DUPLICATE: 'Duplicate',
  MOVE: 'Move',
  ARCHIVE: 'Archive',
  RESTORE: 'Restore',
  DELETE: 'Delete',
  SAVE: 'Save',
};

export const CONDITION_STRINGS = {
  TRUE: 'true',
  FALSE: 'false',
};

export enum VISIBLE_CONDITION {
  AND = 'AND',
  OR = 'OR',
}

export const ANONYMOUS = 'anonymous';

export const STANDALONE_ROOT_ID = 'root';
export const DC_ROOT_ID = 'dc-root';
