/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const MAX_NUMBER_OF_ATTEMPTS = 5;

export const scrollToDomElement = ({ id, attemptCount = 0, block = 'start' as ScrollLogicalPosition, behavior }: { id: string; attemptCount?: number; block?: ScrollLogicalPosition, behavior?: ScrollBehavior }) => {
  const element = document.getElementById(id);

  if (!element && attemptCount < MAX_NUMBER_OF_ATTEMPTS) {
    // if element is not found, try again in 500ms
    setTimeout(() => scrollToDomElement({ id, attemptCount: attemptCount + 1, block }), 500);
    return;
  }

  element?.scrollIntoView({
    behavior: behavior || 'smooth',
    block,
  });
};
