/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ANSWER_TYPES, APP_REVIEW_ROLES, INDICATORS_TYPES, MODAL, REVIEW_TYPE, TABS, USER_REVIEW_ROLES } from '@root/src/lib/common.constants';
import { JSService } from '@root/src/services/js.service';
import { ModalService } from '@root/src/services/modal.service';
import { ScaleService } from '@root/src/services/scale.service';
import { setFilteredDimensionsAction, setOptionsAction, setReviewAction, setUserProgressAction } from '@root/src/store/assessment.actions';
import { IFilterData } from '@root/src/store/store.types';
import { AssessmentStatement, CatalogDimension, IScoreDetailList, IStatementReply, IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { Review } from '@root/src/types/review.types';
import { ResultsFilterService } from '@services/filter.service';
import { ITab } from '@components/page/dimension-page/dimension-page.types';
import { User } from '@root/src/types/user.types';
import { useAssessmentStore } from '@root/src/store/use.assessment.store';
import { useGlobalStore } from '@root/src/store/use.global.store';
import { getStatementCount } from '@root/src/utils/review-helpers.utils';
import { READ_ONLY } from '@root/src/lib/ui-text-block.constants';
import { ReviewService } from '@services/review.service';
import { IUsersScore } from '@components/page/assessment/assessment.types';

export const generateStatementScores = (
  dimensions: CatalogDimension[],
  scores: IScoreDetailList,
  userId: string | undefined,
): IScoreDetailList => {
  if (!JSService.getObjectLength(dimensions)) {
    return {};
  }

  let childScores = {} as IScoreDetailList;

  dimensions.forEach((dimension) => {
    const targetDimension = processDimension(dimension, scores, userId);

    // set scores inside processDimension function (should be refactored)
    childScores = generateStatementScores(targetDimension.dimensions, scores, userId);
  });

  return Object.assign(scores, childScores);
};

const processDimension = (
  dimension: CatalogDimension,
  scores: IScoreDetailList,
  userId: string | undefined,
) => {
  const updatedStatements = dimension.statements.map((statement) => {
    if (statement.userScores && userId && statement.userScores[userId]) {
      const voterScores = statement.userScores[userId]
        .map((voter) => {
          const renderVoter = shouldRenderVoter(
            statement.defaultScores,
            statement.userScores,
            voter.voterId,
            statement.type,
            userId,
          );

          if (renderVoter) {
            return {
              userScore: voter.userScore,
              voterId: voter.voterId,
              ...(voter.userChoices ? { userChoices: voter.userChoices } : null),
            };
          }
          return undefined;
        })
        .filter(Boolean);
      if (voterScores.length) {
        scores[statement.id] = voterScores as ScoreDetail[];
      }
    }
    return statement;
  });

  return {
    ...dimension,
    statements: updatedStatements,
  };
};

export const hasUserVote = (
  voterId: string,
  type: ANSWER_TYPES,
  userScores: IScoreDetailList,
  userId: string,
) => {
  if (!ScaleService.isMultiGroup(type)) {
    return hasUserVoteSingleGroup(userScores, userId, voterId);
  }

  return hasUserVoteMultiGroup(userScores, userId, voterId);
};

export const shouldRenderVoter = (
  defaultScores: IScoreDetailList,
  userScores: IScoreDetailList,
  voterId: string,
  type: ANSWER_TYPES,
  userId: string,
) => {
  const hasDefaultVote = hasUserVote(voterId, type, defaultScores, userId);
  const hasUserVoteValue = hasUserVote(voterId, type, userScores, userId);
  const hasVoteChanged = hasVoteChanges(voterId, userId, type, defaultScores, userScores);

  // this logic affects save payload: if condition is true, then userScore will be included in the payload
  // logic affect visibility of answers on assessment page

  return (
    (hasDefaultVote && hasVoteChanged) ||
    (hasVoteChanged && !hasUserVoteValue) ||
    ((!hasDefaultVote) && hasUserVoteValue) ||
    (ScaleService.isOpenEnded(type) && hasVoteChanged)
  );
};

export const findUserByVoterId = (
  userScores: IScoreDetailList,
  userId: string,
  voterId: string
): ScoreDetail => {
  if (!userId || !userScores || !userScores[userId] || !userScores[userId]?.length) {
    return {} as ScoreDetail;
  }

  return userScores[userId]?.find((user) => user.voterId === voterId) as ScoreDetail;
};

export const hasVoteChangesSingleGroup = (
  defaultScores: IScoreDetailList,
  userScores: IScoreDetailList,
  userId: string,
  voterId: string,
) => {
  const defaultUser = findUserByVoterId(defaultScores, userId, voterId);
  const user = findUserByVoterId(userScores, userId, voterId);

  return defaultUser?.userScore !== user?.userScore;
};

export const hasVoteChangesOpenEndedGroup = (
  defaultScores: IScoreDetailList,
  userScores: IScoreDetailList,
  userId: string,
  voterId: string,
) => {
  const defaultUser = findUserByVoterId(defaultScores, userId, voterId);
  const user = findUserByVoterId(userScores, userId, voterId);
  const defaultChoice =
    defaultUser?.userChoices && defaultUser.userChoices?.length ? defaultUser.userChoices[0] : null;
  const userChoices = user?.userChoices && user.userChoices?.length ? user.userChoices[0] : null;

  return defaultChoice !== userChoices || defaultUser?.userScore !== user?.userScore;
};

export const hasUserVoteSingleGroup = (
  userScores: IScoreDetailList,
  userId: string,
  voterId: string,
) => {
  const user = findUserByVoterId(userScores, userId, voterId);
  return user?.userScore;
};

export const hasUserVoteMultiGroup = (
  userScores: IScoreDetailList,
  userId: string,
  voterId: string,
) => {
  const user = findUserByVoterId(userScores, userId, voterId);
  return user?.userScore || user?.userChoices;
};

export const hasVoteChangesMultiGroup = (
  defaultScores: IScoreDetailList,
  userScores: IScoreDetailList,
  userId: string,
  voterId: string,
) => {
  const defaultUser = findUserByVoterId(defaultScores, userId, voterId);
  const user = findUserByVoterId(userScores, userId, voterId);

  return (
    defaultUser?.userScore !== user?.userScore ||
    !JSService.isEquivalent(defaultUser?.userChoices, user?.userChoices)
  );
};

export const hasVoteChanges = (
  voterId: string,
  userId: string,
  type: ANSWER_TYPES,
  defaultScores: IScoreDetailList = {},
  userScores: IScoreDetailList = {},
) => {
  if (ScaleService.isMultiGroup(type)) {
    return hasVoteChangesMultiGroup(defaultScores, userScores, userId, voterId);
  }
  if (ScaleService.isOpenEnded(type)) {
    return hasVoteChangesOpenEndedGroup(defaultScores, userScores, userId, voterId);
  }

  return hasVoteChangesSingleGroup(defaultScores, userScores, userId, voterId);
};

export const checkFilterData = (isReviewRespondent: boolean, filterConfig: IFilterData) => {
  // check if any dimensions filter is applied
  if (isReviewRespondent) {
    return false;
  }
  return ResultsFilterService.checkFilterDimensions(filterConfig);
};

export const filterDimensions = (dimensions: CatalogDimension[], isReviewRespondent: boolean, filters: IFilterData) => {
  // filter dimensions according to applied configuration state
  if (!dimensions?.length) {
    return [];
  }
  if (!checkFilterData(isReviewRespondent, filters)) {
    return [...dimensions];
  }

  const clearedDimensions = [] as CatalogDimension[];
  const isRangeMaxFilter = parseFloat(filters.maxRange as string) >= 0 && parseFloat(filters.maxRange as string) <= 5;
  const isRangeMinFilter = parseFloat(filters.minRange as string) >= 0 && parseFloat(filters.minRange as string) <= 5;
  const isRangeFilters = isRangeMaxFilter || isRangeMinFilter;
  const isDimensionsFilters = filters.dimensions?.find((e) => e.enabled);
  const isOrder = filters.dimensions?.length;

  if (isRangeFilters) {
    // add dimensions that match range filters
    clearedDimensions.push(
      ...dimensions.filter(
        (dim) =>
          (JSService.roundNumber(dim.expertAvgScore || 0, 1) >= filters.minRange ||
            !isRangeMinFilter) &&
          (JSService.roundNumber(dim.expertAvgScore || 0, 1) <= filters.maxRange ||
            !isRangeMaxFilter),
      ),
    );
  }

  if (isDimensionsFilters) {
    // apply dimensions filters
    const idsOfFilterDimensions = filters.dimensions
      .filter((item) => item.enabled)
      .map((e) => e.dimensionId);
    if (isRangeFilters) {
      // if there is a combination of range + dimension filters then add additional dimensions that are not present yet
      const dimensionsIdsToAdd = idsOfFilterDimensions.filter(
        (id) => !clearedDimensions.find((e) => e.id === id),
      );
      const dimensionsToAdd = dimensions.filter((dimension) =>
        dimensionsIdsToAdd.includes(dimension.id),
      );
      clearedDimensions.push(...dimensionsToAdd);
    } else {
      // if there is only dimension filters then add all these dimensions
      const dimensionsToAdd = dimensions.filter((dimension) =>
        idsOfFilterDimensions.includes(dimension.id),
      );

      clearedDimensions.push(...dimensionsToAdd);
    }
  }

  // reorder dimensions according to filters
  const condition = getCondition(isRangeFilters, isDimensionsFilters, isOrder);

  return setFilteredDimensionsAction(reOrderClearedDimensions(condition, isOrder, dimensions, clearedDimensions, filters));
};

const getCondition = (isRangeFilters: boolean, isDimensionsFilters: CatalogDimension | undefined, isOrder: number) => !isRangeFilters && !isDimensionsFilters && isOrder;

const reOrderClearedDimensions = (condition: number | boolean, isOrder: number, dimensions: CatalogDimension[], clearedDimensions: CatalogDimension[], filters: IFilterData) => {
  if (condition) {
    // if there are no filters applied, but order was changed, then apply reording to all dimensions
    return reorderFilteredItems(dimensions, filters);
  }
  if (isOrder) {
    return reorderFilteredItems(clearedDimensions, filters);
  }
  return clearedDimensions;
};

export const reorderFilteredItems = (dimensions: CatalogDimension[], filters: IFilterData) => {
  // Reorder items according to config

  const alreadyFilteredDimensions = [] as CatalogDimension[];
  let newAddedDimensions = [] as CatalogDimension[];
  // find some dimensions that currently are not present in filter config (for example, new dimensions)
  // and put them to new array
  dimensions.forEach((dim) => {
    const filterDimension = filters.dimensions.find((e) => e.dimensionId === dim.id);
    if (filterDimension) {
      alreadyFilteredDimensions.push({
        ...dim,
        order: filterDimension.order,
      });
    } else {
      newAddedDimensions.push(dim);
    }
  });
  // if there are new dimensions then add them the biggest order to place them to the end of a list
  newAddedDimensions = newAddedDimensions.map((item, index) => ({
    ...item,
    order:
      index +
      1 +
      Math.max.apply(
        null,
        alreadyFilteredDimensions.map((e) => e.order),
      ),
  }));

  let reorderedDimensions = [...alreadyFilteredDimensions, ...newAddedDimensions];
  // sort dimensions by order
  reorderedDimensions = reorderedDimensions.sort(
    (dimensionA, dimensionB) => dimensionA.order - dimensionB.order,
  );
  return reorderedDimensions;
};

export const openAcceptModal = (callback: () => void) => {
  ModalService.openAcceptModal({
    title: 'Notification',
    description:
      'You have not saved your progress in the Assessment. Do you want to continue without saving?',
    onAccept: callback,
  });
};

export const showLeaveModal = (onLeave: () => void, onSaveAndLeave: () => void) => {
  ModalService.openLeaveModal({
    title: MODAL.LEAVE.title,
    description: MODAL.LEAVE.description,
    onLeave,
    onSaveAndLeave,
  });
};

export const calculateProgress = (selectedCount: number, count: number) => (selectedCount * 100) / count;

export const getSelectedStatementCount = (dimensionParent: CatalogDimension, review: Review, userId: string, voterNumber: number, voters: IVoter[]) => {
  let count = 0;

  if (dimensionParent.dimensions) {
    dimensionParent.dimensions && dimensionParent.dimensions.forEach((dimension) => {
      count += getSelectedStatementCount(dimension, review, userId, voterNumber, voters);
    });
  }
  if (dimensionParent.statements) {
    const isExpert = (getCurrentUserRoles(review, userId) as string[]).includes(
      APP_REVIEW_ROLES.REVIEW_EXPERT,
    );
    const voterValue = voterNumber ? voters?.find((item) => item.order.toString() === voterNumber.toString())
      : {} as IVoter;

    dimensionParent.statements.forEach((statement) => {
      const voterInStatementScores =
        statement.userScores && statement.userScores[userId] && voterValue
          ? statement.userScores[userId].find((user) => user.voterId === voterValue.id)
          : {} as ScoreDetail;

      const hasUserScore = voterInStatementScores?.userScore;
      const hasUserChoices = voterInStatementScores?.userChoices?.length;
      const isRated = statement.rated;
      const countUpCondition1 = !ScaleService.isMultiGroup(statement.type) && hasUserScore;
      const countUpCondition2 = getCondition2(isRated, isExpert, hasUserScore);
      const countUpCondition3 = getCondition3(isRated, isExpert, hasUserChoices as number);
      const countUpCondition4 = !isRated && hasUserChoices;

      if (countUpCondition1 || countUpCondition2 || countUpCondition3 || countUpCondition4) {
        count++;
      }
    });
  }
  return count;
};

const getCondition2 = (isRated: boolean, isExpert: boolean, hasUserScore: INDICATORS_TYPES | null | undefined) => isRated && isExpert && hasUserScore;

const getCondition3 = (isRated: boolean, isExpert: boolean, hasUserChoices: number) => isRated && !isExpert && hasUserChoices;

export const getCurrentUserRoles = (review: Review, userId: string) => (review?.users?.find((user) => user.id === userId) || {}).roles || [];

export const getAssessmentTabs = () => [
  TABS.NAVBAR.ASSESSMENT[0],
  TABS.NAVBAR.ASSESSMENT[1],
  TABS.NAVBAR.ASSESSMENT[2],
  TABS.NAVBAR.ASSESSMENT[3],
] as ITab[];

export const getScoresForVoter = (voterId: string, userId: string, dimensions: CatalogDimension[] = [], voterScores: IScoreDetailList = {}) => {
  let childVoterScores = {} as IScoreDetailList;
  if (!dimensions.length) {
    return {} as IScoreDetailList;
  }
  dimensions.forEach((dimension) => {
    dimension.statements.forEach((statement) => {
      const votes = (statement.userScores[userId] || []).filter(
        (voter) => voter.voterId === voterId && (voter.userScore || voter.userChoices),
      );
      if (votes.length) {
        voterScores[statement.id] = votes;
      }
    });
    childVoterScores = getScoresForVoter(voterId, userId, dimension.dimensions, voterScores);
  });

  return Object.assign(voterScores, childVoterScores);
};

export const updateReviewDimensions = (
  newDimensions: CatalogDimension[],
  currentDimensions: CatalogDimension[]
): CatalogDimension[] => {
  const updateDimensions = (dimensions: CatalogDimension[], dimension: CatalogDimension): CatalogDimension[] =>
    dimensions.map((dim) =>
      dim.id === dimension.id
        ? dimension
        : {
            ...dim,
            dimensions: updateDimensions(dim.dimensions, dimension),
          }
    );

  let updatedDimensions = currentDimensions;

  newDimensions.forEach((dimension) => {
    updatedDimensions = updateDimensions(updatedDimensions, dimension);
  });

  return updatedDimensions;
};

export const modifyDimensionData = (dimensions: CatalogDimension[], updatedStatements: AssessmentStatement[], dimensionId: string) =>
  dimensions?.map((dimension) => {
    if (dimension.id === dimensionId) {
      const currentStatementsIds = dimension.statements.map((statement) => statement.id);
      const currentStatementUpdates = updatedStatements.filter((statement) =>
        currentStatementsIds.includes(statement.id),
      );

      dimension.statements = currentStatementUpdates;
    } else if (dimension.dimensions?.length) {
      dimension.dimensions = modifyDimensionData(
        dimension.dimensions,
        updatedStatements,
        dimensionId,
      );
    }
    return dimension;
  });

const filterParticipants = (participants: User[], config: IFilterData, voters: IVoter[]) => {
  if (!voters) {
    return;
  }
  return participants.filter((participant) => {
    const participantsPagesIds = voters
      .filter((e) => e.userId === participant.id)
      .map((vote) => vote.id);
    return JSService.findCommonElements(config.voterIds, participantsPagesIds);
  });
};

const checkVotersFilter = (config: IFilterData) => ResultsFilterService.checkFilterVoters(config);

const getFilteredUsers = (users: User[], roleName: string, filterConfig: IFilterData, voters: IVoter[]): IUsersScore | null => {
  const filteredUsers = checkVotersFilter(filterConfig) ? filterParticipants(users, filterConfig, voters) : users;

  return filteredUsers?.length ? { name: roleName, users: filteredUsers } : null;
};

export const totalOptions = (
  review: Review,
  voters: IVoter[],
  userId: string,
  isReviewRespondent: boolean,
  filterConfig: IFilterData,
) => {
  const clientRoleName = `${USER_REVIEW_ROLES.REVIEW_CLIENT.name}`;
  const expertRoleName = `${USER_REVIEW_ROLES.REVIEW_EXPERT.name}s`;

  if (isReviewRespondent) {
    return [
      review?.clients?.length ? {
        name: clientRoleName,
        users: [review.clients.find((e) => e.id === userId)].filter(Boolean) as User[],
      } : null,
    ].filter(Boolean) as IUsersScore[];
  }

  return [
    review?.experts?.length ? getFilteredUsers(review.experts, expertRoleName, filterConfig, voters) : null,
    review?.clients?.length ? getFilteredUsers(review.clients, `${clientRoleName}s`, filterConfig, voters) : null,
  ].filter(Boolean) as IUsersScore[];
};

export const createBenchmarksObject = (dimensions: CatalogDimension[]) =>
  dimensions.reduce((result, dimension) => {
    result[dimension.id] = dimension.expectedScore?.toFixed(1);

    if (dimension.dimensions.length) {
      Object.assign(result, createBenchmarksObject(dimension.dimensions));
    }
    return result;
  }, {});

export const setSelectedStatementCount = (dimensions: CatalogDimension[]): CatalogDimension[] | null => {
  if (!dimensions) {
    return null;
  }
  const { voterNumber, review, voters } = useAssessmentStore.getState().assessmentState;
  const { currentUser } = useGlobalStore.getState();

  return dimensions.map((dimension: CatalogDimension) => {
    dimension.selectedStatementCount = getSelectedStatementCount(dimension, review, (currentUser as User)?.id, voterNumber as number, voters || []);

    if (dimension.dimensions?.length) {
      return {
        ...dimension,
        dimensions: setSelectedStatementCount(dimension.dimensions) as CatalogDimension[],
      } as CatalogDimension;
    }

    return dimension;
  });
};

export const getUserProgress = () => {
  const { voterNumber, review, voters, options } = useAssessmentStore.getState().assessmentState;
  const targetDimensions = review.dimensions;
  const { currentUser } = useGlobalStore.getState();

  if (!JSService.getObjectLength(targetDimensions)) {
    return;
  }

  let statementsCount = 0;
  let selectedStatementCount = 0;

  targetDimensions.forEach((dimension) => {
    statementsCount += getStatementCount(dimension);
    selectedStatementCount += getSelectedStatementCount(dimension, review, (currentUser as User)?.id, voterNumber as number, voters || []);
  });

  const updatedUserProgress = calculateProgress(selectedStatementCount, statementsCount);

  setUserProgressAction(updatedUserProgress);
  setOptionsAction({
    ...options,
    info: {
      ...options.info,
      value: setProgressMessage(options.info, updatedUserProgress),
    },
  });
};

const setProgressMessage = (info: { [key: string]: string }, updatedUserProgress: number) => {
  if (!info) {
    return '';
  }
  if (info.value === READ_ONLY) {
    return READ_ONLY;
  }
  return calculateProgressPercent(updatedUserProgress);
}

export const calculateProgressPercent = (userProgressValue: number) => `${JSService.roundNumber(
  parseFloat(userProgressValue?.toString()) || 0,
  0,
)}%`;

export const updateReplyData = (
  allVoters: IVoter[],
  replyId: string,
  reply: IStatementReply| null,
  voterId: string,
  isExist: boolean,
) => allVoters?.map((voter) => {
    if (voter.id === voterId) {
      return {
        ...voter,
        replies: updatedVoterReply(voter?.replies || [], reply, replyId, voterId, isExist),
      };
    }

    return voter;
  });

const updatedVoterReply = (
  replies: IStatementReply[],
  reply: IStatementReply | null,
  replyId: string,
  voterId: string,
  isExist: boolean
) => {
    // add new reply
    if (replyId && !isExist && reply) {
      return [...replies, reply];
    }

    // remove reply
    if (voterId && !reply && isExist) {
      return replies.filter((item) => item.id !== replyId);
    }

    // edit existing reply
    if (voterId && isExist && reply) {
      return replies.map((item) => (item.voterId === voterId && item.id === replyId) ? reply : item);
    }
    return replies;
};

export const getOpenedIDs = (currentDimensions: CatalogDimension[], isOpened: boolean) => {
  const { openedIDs } = useAssessmentStore.getState().assessmentState;
  let updatedIDs = [...openedIDs];

  currentDimensions?.forEach(dimension => {
    if (isOpened) {
      updatedIDs = [...updatedIDs, dimension.id]
    } else {
      updatedIDs = updatedIDs.filter((id) => id !== dimension.id)
    }

    updatedIDs = updateIDs(updatedIDs, dimension, isOpened);

    dimension.statements.forEach((statement) => {
      updatedIDs = updateIDs(updatedIDs, statement, isOpened);
    });

    if ( dimension.dimensions?.length) {
      updatedIDs = getOpenedIDs(dimension.dimensions, isOpened);
    }
  });

  return updatedIDs;
};

export const updateIDs = (updatedIDs: string[], target: CatalogDimension | AssessmentStatement, isOpened: boolean) => {
  if (isOpened) {
    return [...updatedIDs, target.id]
  }
    return updatedIDs.filter((id) => id !== target.id)
};

export const getIDsOfDimensions = (items: CatalogDimension[] = []) => items?.reduce((acc: string[], item: CatalogDimension): string[] => {
  acc.push(item.id);

  if (item.dimensions?.length) {
    return [...acc, ...getIDsOfDimensions(item.dimensions)];
  }
  return acc;
}, []);

export const filterPageScore = (review: Review, voterId: string, userId: string) => {
  const updatedDimensionsAfterCommentFiltering = structuredClone(review.dimensions).map((dimension) =>
    filterComments(dimension, voterId),
  );

  const updatedDimensionsAfterScoresFiltering = updatedDimensionsAfterCommentFiltering.map((dimension) =>
    filterScores(dimension, voterId, userId),
  );

  setReviewAction({ ...review, dimensions: updatedDimensionsAfterScoresFiltering as CatalogDimension[] });
};

const filterComments = (dimension: CatalogDimension, voterId: string): CatalogDimension => {
  const updatedStatements = dimension.statements.map((statement) => {
    const updatedComments = statement.comments?.filter((comment) => comment.voterId !== voterId);

    return { ...statement, comments: updatedComments };
  });

  if (dimension.dimensions?.length) {
    const updatedDimensions = dimension.dimensions.map((childDimension) =>
      filterComments(childDimension, voterId),
    );

    return { ...dimension, dimensions: updatedDimensions, statements: updatedStatements };
  }

  return { ...dimension, statements: updatedStatements };
};

const filterScores = (dimension: CatalogDimension, voterId: string, userId: string): CatalogDimension => {
  const updatedStatements = dimension.statements.map((statement) => ({ ...statement, userScores: {
      ...statement.userScores,
      [userId]: statement.userScores[userId]?.filter((user) => user.voterId !== voterId),
    } }));

  if (dimension.dimensions?.length) {
    const updatedDimensions = dimension.dimensions.map((childDimension) =>
      filterScores(childDimension, voterId, userId),
    );

    return { ...dimension, dimensions: updatedDimensions, statements: updatedStatements };
  }

  return { ...dimension, statements: updatedStatements };
};

export const canEdit = (user: User, experts?: User[]) => {
  const { review: reviewData } = useAssessmentStore.getState().assessmentState;
  const expertList = experts?.length ? experts : reviewData?.experts;

  return reviewData && expertList && ReviewService.hasUser(expertList, (user as User)?.id) && !ReviewService.isFinished(reviewData)
};

export const filterStatements = (currentDimensions: CatalogDimension[], statementIds: string[]): CatalogDimension[] =>
  currentDimensions.map((dimension) => {
    const filteredStatements = dimension.statements.filter(({ id }) => statementIds.includes(id));
    const filteredDimensions = dimension.dimensions ? filterStatements(dimension.dimensions, statementIds) : [];

    return {
      ...dimension,
      statements: filteredStatements,
      dimensions: filteredDimensions,
    };
  });

  export const getAllStatements = (currentDimensions: CatalogDimension[], statements: AssessmentStatement[] = []): AssessmentStatement[] => (currentDimensions || []).reduce((allStatements, dimension) => {
      const dimensionStatements = dimension.statements;
      const nestedStatements = dimension.dimensions ? getAllStatements(dimension.dimensions) : [];

      return [...allStatements, ...dimensionStatements, ...nestedStatements];
    }, statements);

export const replaceStatements = (currentDimensions: CatalogDimension[], statements: AssessmentStatement[]): CatalogDimension[] => {
  const statementMap = new Map(statements.map(statement => [statement.id, statement]));

  return currentDimensions.map(dimension => {
    const replacedStatements = dimension.statements.map(statement =>
      statementMap.get(statement.id) || statement
    );

    const replacedDimensions = dimension.dimensions ? replaceStatements(dimension.dimensions, statements) : [];

    return {
      ...dimension,
      statements: replacedStatements,
      dimensions: replacedDimensions,
    };
  });
};

export const isReviewHasSurveyLogic = (review: Review) => {
  if (review.reviewType !== REVIEW_TYPE.SURVEY) {
    return false;
  }

  return review.reviewTemplate?.templateLogic?.length > 0;
}
